@import '../../../default';

.page-success{
    background-color: $color-1;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before{
        display: none !important;
    }

    .box{
        background-color: #FFF;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        width: 100%;
        max-width: 300px;

        .link{
            background-color: $color-1;
            color: #FFF;
            border-radius: 5px;
            display: inline-flex;
            box-sizing: border-box;
            padding: 10px 20px;

            a{
                color: #FFF;
                text-decoration: none;
            }
        }
    }
}