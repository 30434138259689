@import '../../default';

.page-form{

    background-color: $color-1;
    height: 100%;
    min-height: 100vh;
    position: relative;
    

    &::after{
        position: fixed;
        width: 100vw;
        height: 100vh !important;
        top: 0;
        content: '';
        display: block;
        background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.5)), 
        linear-gradient(to left, rgba(116, 9, 48, 0.9), rgba(116, 9, 48, 0.9)),
        url(../../../image/form_bg.jpg);
        background-size: cover;
        background-attachment: fixed;
    }

    header{
        height: 80px;
        width: 100%;
        background-color: #FFF;
        position: fixed;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: 200px 1fr;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px 20px;
        color: #FFF;
        font-size: 2em;
        z-index: 20;

        .actions{
            display: flex;
            justify-content: flex-end;
        }
    }

    section {
        position: relative;
        z-index: 5;
        h1{
            color: #FFF;
        }

        box-sizing: border-box;

        @media screen and (max-width: 1000px) {
            padding: 10px 20px;
        }
    }

    counter-reset: number;

    .area-button{
        margin: 20px auto 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button{
            border:0;
            border-radius: 5px;
            background-color: $color-1;
            color: #FFF;
            box-sizing: border-box;
            padding: 10px;
            width: 100%;
            max-width: 150px;
            cursor: pointer;
        }
    }
    
    .questions-area{
        width: 98%;
        max-width: 1000px;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
        margin: 10px auto;   
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.9);

        .question{
            display: flex;
            align-items: center;
            counter-increment: number;
            box-sizing: border-box;
            padding: 10px 0;

            &::before{
                display: inline-flex;
                margin-right: 5px;
                content: counter(number) ".";
                font-weight: 800;
                font-size: 1.5em;
                color: $color-1;
            }
        }

        textarea{
            width: 100%;
            height: auto;
            min-height: 100px;
            resize: none;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid $color-4;
            font-weight: 100;

            &.erro{
                background-color: $color-4;
            }
        }
    }

    .extra-info{
        margin: 20px auto 40px;
        p{
            a, &:visited{
                color: $color-1 !important;
                font-weight: 400;
            }
        }
    }
}