@import '../../../../default';

.ui-carrossel-depoiment{

    .depoiment-area{
        box-sizing: border-box;
        width: 100%;
        min-height: 400px;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        perspective-origin: center center;
        position: relative;
        transform-origin: center center;
        flex-wrap: wrap;

        &::before{
            width: 200px;
            height: 200px;
            content: '';
            display: block;
            position: absolute;
            background-image: radial-gradient(circle, $color-5 10%, transparent 10%);
            background-size: 15px 15px;
            z-index: 2;
            bottom: 50%;
            left: 60%;
        }

        &::after{
            width: 200px;
            height: 200px;
            content: '';
            display: block;
            position: absolute;
            background-image: radial-gradient(circle, $color-5 10%, transparent 10%);
            background-size: 15px 15px;
            z-index: 2;
            top: 50%;
            right: 60%;
        }

        .navigation{
            margin: 30px auto 10px;
            box-sizing: border-box;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            align-items: flex;
            justify-content: flex-end;
            width: 100%;
            max-width: 550px;
            position: relative;
            z-index: 3;

            > div{
                box-sizing: border-box;
                padding: 5px 5px;
                position: relative;
                transition: all ease 600ms 300ms;
                cursor: pointer;
                background-color: $color-1;
                color: #FFF;

                &:first-child{
                    border-radius: 5px 0 0 5px;
                }

                &:last-child{
                    border-radius: 0 5px 5px 0;
                }

                &:hover{
                    transition: all ease 600ms;
                }
            }

        }

        .area-people{
            width: 100%;
            max-width: 800px;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-style: preserve-3d;
            perspective: 1000px;
            perspective-origin: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            transform: translate(-50%, -50%);
            z-index: 3;
        }

        .people{
            border: 1px solid #FFF;
            border-radius: 50%;
            width: 200px;
            height: 200px;
            position: absolute;
            transform-origin: center center;
            background-color: $color-4;
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
            margin: 10px;
            transform: translateZ(-800px);
            animation: peopleanim ease 2s infinite;

            @keyframes peopleanim {
                0%, 50%, 100%{
                    margin-top: 0;
                }
                25%{
                    margin-top: -10px;
                }
                75%{
                    margin-top: 10px;
                }
            }

            &:nth-child(1){
                top: -10%;
                left: -20%;
                transform: translateZ(-100px);
                animation-delay: 1s;
            }

            &:nth-child(2){
                top: 0;
                left: -80%;
                animation-delay: 1s;
                transform: translateZ(-500px);
            }

            &:nth-child(3){
                // transform: translate3d(-450%, 0, 0px) scale(0.7);
                top: 50%;
                left: -50%;
                animation-delay: 1s;
                transform: translateZ(-800px);
                animation-delay: 2s;
            }

            &:nth-child(4){
                top: -50%;
                right: -80%;
                animation-delay: 1s;
                transform: translateZ(-1300px);
            }

            &:nth-child(5){
                top: 20%;
                right: -40%;
                animation-delay: 2s;
                transform: translateZ(-700px);
            }

            &:nth-child(6){
                top: 50%;
                right: -40%;
                animation-delay: 2s;
                transform: translateZ(-100px);
            }
        }

        .details-cards{
            display: block;
            background-color: $color-1;
            transform: translate(-50%, -50%);
            box-sizing: border-box;
            padding: 20px;
            border-radius: 10px;
            color: #FFF;
            width: 90%;
            height: 200px;
            z-index: 10;
            position: absolute;
            max-width: 510px;
            top: 50%;
            left: 50%;
            margin-top: 10px;
        }

        .content{
            background-color: $color-1;
            transform: translateZ(500px);
            box-sizing: border-box;
            padding: 20px;
            border-radius: 10px;
            color: #FFF;
            width: 100%;
            max-width: 550px;
            min-height: 272px;
            z-index: 100;
            position: relative;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);

            &::before{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%,-50%);
                content: '';
                display: block;
                border: 2px solid #FFF;
                z-index: 18;
            }

            .photo{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: $color-4;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%,-50%);
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                img{
                    width: 100%;
                    max-width: 100px;
                    display: block;
                }
            }

            .text{
                margin: 60px auto;
                width: 80%;
                box-sizing: border-box;
                padding: 20px;
                position: relative;
                z-index: 20;

                .detail-1{
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translate(-50%, -50%);
                }

                .detail-2{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translate(50%, 50%);
                }

            }

            .client{
                text-align: center;
                font-size: 0.8em;
            }


        }
    }

}