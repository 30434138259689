@import '../../default';

.component-contact{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
        padding: 10px;
    }

    .contact-subtitle{
        font-size: 1.2em !important;

        @media screen and (max-width: 600px) {
            box-sizing: border-box;
            padding: 0 10px !important;
        }

        @media screen and (max-width: 768px) {
            box-sizing: border-box;
            padding: 0 20px !important;
        }
    }

    .list-contacts{
        
        .item{
            display: grid;
            grid-template-columns: 40px 1fr;
            row-gap: 15px;
            margin: 15px auto;
            align-items: center;

            a{
                text-decoration: none;
                color: unset;
            }

            .area-icon{
                .icon{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: $color-1;
                    color: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    form{

        > div {
            margin: 10px auto;
        }
        input, select, textarea{
            border: 3px solid $color-2;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 5px;
            width: 100%;
            outline: none;
            background-color: #FFF !important;

            &::selection,
            &:-internal-autofill-selected,
            &:focus,
            &:active,
            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active,
            &:-webkit-autofill:selected{
                outline: 0;
                background-color: #FFF !important;
                box-shadow: unset !important;
                -webkit-text-fill-color: unset !important;
                text-fill-color: #FFF !important;
            }

            &[data-autocompleted] {
                background-color: #FFF !important;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus,
        input:autofill,
        input:autofill:hover, 
        input:autofill:focus,
        textarea:autofill,
        textarea:autofill:hover,
        textarea:autofill:focus,
        select:autofill,
        select:autofill:hover,
        select:autofill:focus  {
            outline: 0;
            background-color: #FFF !important;
            box-shadow: unset !important;
            -webkit-text-fill-color: unset !important;
            text-fill-color: #FFF !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus,
        select:-webkit-autofill:active,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        textarea:-webkit-autofill:active {
            transition: background-color 5000000s ease-in-out 0s;
        }

        textarea{
            resize: none;
        }

        button{
            color: #FFF;
            background-color: $color-1;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 10px;
            border: 0;
            min-width: 120px;
            cursor: pointer;
        }
    }

    .title{
        box-sizing: border-box;
        padding: 10px 0;
        font-size: 2em;
        font-weight: 400;
        color: $color-1;
        font-family: 'Eras Medium ITC', sans-serif;

        @media screen and (max-width: 600px) {
            font-size: 1.5em;
        }
    }

    .subtitle{
        box-sizing: border-box;
        padding: 10px 0;
        font-size: 1.5em;
        font-weight: 400;
        color: $color-1;
        font-family: 'Eras Medium ITC', sans-serif;

        @media screen and (max-width: 600px) {
            font-size: 1.2em;
        }
    }
}