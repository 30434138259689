@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap');

@font-face {
    font-family: 'Eras Medium ITC';
    src: url('../font/ErasITC-Medium.woff2') format('woff2'),
        url('../font/ErasITC-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icon';
    font-style: italic;
    font-weight: 200;
    src: url(../font/icon.woff) format("woff"); 
}
  
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
      font-family: 'icon';
      src: url(../font/icon.svg) format("svg"), url(../font/icon.woff) format("woff"); 
    } 
}

@import "./icon";

@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";                
@import "primeicons/primeicons.css";  
@import "primeflex/primeflex.css";

// $color-1:#EA497D;
// $color-2:#FFC6DB;
// $color-3:#FFFFFF;
// $color-4:#AEDCF0;
// $color-5:#0085C6;

// $color-1:#9B7ED9;
// $color-2:#7D6AA6;
// $color-3:#A988F2;
// $color-4: #262524;
// $color-5: #BFBFBF;


// $color-1:#F7B4C1;
// $color-2:#F8D0D1;
// $color-3:#F2F6F9;
// $color-4:#2C92E5;
// $color-5:#333;

$color-1:#740930;
$color-2:#666666;
$color-3:#A6CBE9;
$color-4:#FDACC8;
$color-5:#E7AFCF;

$color-background: #FFF;

$color-background-1: $color-1;

$color-text-1: #333;
$color-text-2: $color-3;

html{

    width: 100vw;
    height: 100vh;
    position: fixed;
    margin: 0;
    padding: 0;

    body{
        width: 100%;
        height: 100%;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 300;
        overflow: hidden;
        margin: 0;
        padding: 0;
        background-color: $color-background;
        color: $color-text-1;

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }

        #root{
            width: 100%;
            height: 100%;
            overflow: auto;
            scroll-behavior: smooth;
            scroll-snap-type: y mandatory;
            overflow-x: hidden;

            [class*="page-"]{
                margin: 0 auto;

                &::before{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 80px;
                    width: 100%;
                    color: $color-1;
                }

                .main-frame{
                    width: 100%;
                    margin: 0 auto;
                    box-sizing: border-box;

                    .title{
                        box-sizing: border-box;
                        padding: 10px 0;
                        font-size: 2em;
                        font-weight: 400;
                        color: $color-1;
                        font-family: 'Eras Medium ITC', sans-serif;

                        @media screen and (max-width: 600px) {
                            font-size: 1.5em;
                        }
                    }

                    .subtitle{
                        box-sizing: border-box;
                        padding: 10px 0;
                        font-size: 1.5em;
                        font-weight: 400;
                        color: $color-1;
                        font-family: 'Eras Medium ITC', sans-serif;

                        @media screen and (max-width: 600px) {
                            font-size: 1.2em;
                        }
                    }
                }
            }

        }

        .img-responsive{
            display: block;
            width: 100%;
        }
    }

}