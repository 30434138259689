@import '../../../default';

.ui-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;

    .text{
        width: 278px;
        display: flex;
        position: relative;
        justify-content: center;

        > div{
            position: relative;

            > div{
                position: relative;
            }
        }

        > div:nth-child(1){
            width: 100%;
            max-width: 245px;
            animation: animlogo 2s 0.5s ease infinite alternate-reverse;
            z-index: 2;

            @keyframes animlogo {
                0%{
                    max-width: 29px;
                }

                50%{
                    max-width: 245px;
                }
            }
            
            > div{
                width: 100%;
                overflow: hidden;
            }

            img{
                width: 242px;
            }

            &::before{
                content: '';
                display: block;
                position: absolute;
                top: 14px;
                right: 0;
                width: 80%;
                height: 2px;
                background-color: #df2c59;
                transform: translate(15px, -50%);
                
    
            }
        }

        > div:nth-child(2){
            overflow: hidden;
            z-index: 1;
            
            img{
                display: block;
                width: 29px;
            }
        }

        img{
            display: inline-block;
        }
    }
}