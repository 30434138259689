@import '../../../../default';

.menu-pages{
    
    position: fixed !important;
    z-index: 1000;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    color: #EEE;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    row-gap: 10px;
    column-gap: 10px;
    box-sizing: border-box;
    padding: 20px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    transition: all ease 1s;

    &.fixed{
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
        transition: all ease 1s;

        .button-menu{
            color: $color-1;
        }

        li{

            &::before{
                background-color: $color-1 !important;
            }

            a{
                color: $color-1 !important;
                position: relative;

                ul{
                    position: absolute;

                    li{
                        a{
                            color: #FFF !important;
                        }
                    }
                }

               

            }

            @media screen and (max-width: 600px) {

                &::before{
                    background-color: transparent !important;
                }
    
                a{
                    color: #FFF !important;
    
                }   
            }
        }
    }

    .logo{
        position: absolute;
        top: 40px;
        left: 10px;
        transform: translateY(-50%);
    }

    .button-menu{
        position: absolute;
        top: 40px;
        right: 10px;
        transform: translateY(-50%) scaleX(-1);
        display: none !important;
        pointer-events: auto;

        @media screen and (max-width: 600px) {
            display: block !important;
        }
    }

    .area-menu{
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        .area-menu{
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            width: 100vw;
            max-width: 300px;
            height: 100%;

            pointer-events: none;

            &::after{
                content: '';
                display: block;
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
                z-index: 100;
                pointer-events: none;
                opacity: 0;
            }

            &.show{
                pointer-events: auto;

                &::after{
                    opacity: 1;
                    pointer-events: auto;
                }

                .button-menu{
                    color: #FFF;
                    z-index: 10000;
                }


            }
        }       
    }

    .menu{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        max-width: 1000px;
        height: 100%;

        @media screen and (max-width: 768px) {
            justify-content: flex-end;

        }

        @media screen and (max-width: 600px) {
            display: flex;
            opacity: 0;
            pointer-events: none;
            transition: all ease 600ms;
            flex-direction: column;
            justify-content: center;
            align-self: center;
            position: fixed;
            z-index: 1000;
            background-color: $color-1;
            font-size: 1.5em;
            transform: translateX(100%);
            max-width: 300px;

        }

        &.show{
            @media screen and (max-width: 600px) {
                opacity: 1;
                pointer-events: auto;
                transition: all ease 600ms;
                transform: translateX(0);
            }
        }

       > li{
            list-style: none;
            padding: 10px 0px;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            margin: 0 10px;
            font-family: 'Eras Medium ITC', sans-serif;

            @media screen and (max-width: 600px) {
                max-width: 280px;
                margin: 0 auto;
            }

            a {
                color:#FFF;
                text-decoration: none;
                // width: 100%;
                display: inline-block;
                cursor: pointer;
                position: relative;

                @media screen and (max-width: 600px) {
                    text-align: center;
                    width: 100%;
                }


                ul{

                    &::before{
                        content:'';
                        display: block;
                        width: 10px;
                        height: 10px;
                        background-color: inherit;
                        position: absolute;
                        top: 0;
                        left: 10px;
                        transform: rotate(45deg) translateY(-50%);
                        z-index: 10;
                        border-radius: 10px;
                    }

                    position: absolute;
                    background-color: $color-1;
                    min-width: 320px;
                    width: 100%;
                    max-width: 350px;
                    text-align: left;
                    margin: 20px auto;
                    padding: 20px;
                    box-sizing: border-box;
                    z-index: 10;
                    display: none;

                    li{
                        list-style: none;
                        padding: 0;
                        margin: 10px auto;

                    }

                    @media screen and (max-width: 600px) {
                        display: block;
                        position: relative;
                        margin: 0px auto;
                        padding: 1px;
                        font-size: 0.7em;
                        max-width: 290px;
                        min-width: 280px;

                        li{
                            padding: 5px 10px;
                            box-sizing: border-box;
                            background-color: rgba(255, 255, 255, 0.5);
                            position: relative;

                            &::before{
                                content:'';
                                display: block;
                                width: 0;
                                height: 0;
                                background-color: rgba(255, 255, 255, 0.5) !important;
                                border-radius: 0 !important;
                                border-width: 10px !important;
                                border-color: #FFF !important;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: rotate(45deg) translate(-50%, -50%);
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }

            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 60%;
                width: 100%;
                height: 1px;
                background-color: #EEE;
                transition: transform ease 600ms 300ms;
                transform: scaleX(0.5);
                transform-origin: 0 center;

                @media screen and (max-width: 600px) {
                    left: 50%;
                    margin-left: -50%;
                    background-color: transparent;
                    
                }
            }

            &:hover::before{
                transition: transform ease 300ms;
                transform: scaleX(1);
                filter: blur(1px) contrast(4);
            }

            > a{  
                height: 100%;
                min-height: 40px;

                a:hover{
                    text-decoration: underline;
                }
            }

            a:hover ul{
                display: block;
            }

        }
    }
}