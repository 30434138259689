@import '../../../../default';

.ui-carrossel-banner{

    display: grid;
    grid-template-columns: 1fr;
    // grid-template-rows: minmax(100px, 1fr) 40px;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 120px);
    position: absolute;
    
    .bg-color{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(../../../../../image/olga_top_1_bg.jpg);
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;
        background-attachment: fixed;
        display: block;
        z-index: 1;

    }

    .bg-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: auto 90%;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        display: block;
        z-index: 2;

        &.full{
            background-size: cover;
            background-position: top center;
        }

        &.cover-1{
            background-position: top center;
            background-size: cover;
            @media screen and (max-width: 600px) {
                background-position: top left;
            }
        }

        &.cover-2{
            background-position: top center;
            background-size: cover;
            @media screen and (max-width: 600px) {
                background-position: top center;
            }
        }

        &.normal{
            background-position: top center;
            background-size: cover;

            @media screen and (max-width: 600px) {
                background-position: top center;
            }
        }

    }

    .card{    
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;

        &.current{
            display: grid;
            opacity: 1;
            transition: ease all 600ms 300ms;
        }
        
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        opacity: 0;
        transition: ease all 300ms ;

        .content{
            align-items: flex-end;
            box-sizing: border-box;
            padding: 10px;

            > div{
                background-image: linear-gradient(to left, transparent 0%, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0.5) 90%, transparent 100%);
                box-sizing: border-box;
                padding: 10px;
                text-align: center;
            }
        }
    }

    .cards-list{

        box-sizing: border-box;
        padding: 20px 0;
        position: relative;
        z-index: 10;

        @media screen and (max-width: 1024px) {
            padding: 10px;
        }

        .content{
            position: absolute;
            top: 80%;
            left: 0;
            width: 100%;
            display: none;
        }
    }

    .navigation{
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        position: absolute;
        z-index: 100;
        top: 90%;
        
        .button{
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 30px;
            height: 30px;
            max-width: 30px;
            width: 30px;
            cursor: pointer;
            box-sizing: border-box;
            font-family: 'Eras Medium ITC', sans-serif;
            margin: 10px;

            &::before{
                content: '';
                display: block;
                max-height: 15px;
                min-height: 15px;
                height: 15px;
                max-width: 15px;
                min-width: 15px;
                width: 15px;
                transition: all linear 600ms 300ms;
                box-sizing: border-box;
                background-color: #FFF;
                border-radius: 50%;
            }

            &.current::before{
                background-color: $color-1;
                transition: all linear 600ms;
            }
        }
    }

}