@import '../../../../default';

.ui-carrossel-post{

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    width: 100%;
    margin: 10px auto;
    max-width: 1000px;
    min-height: 550px;
    height: auto;
    row-gap: 20px;
    position: relative;

    @media screen and (max-width: 768px) {
        box-sizing: border-box;
        padding: 10px;
        min-height: 400px;
        
    }

    @media screen and (max-width: 600px) {
        min-height: 800px;
        
    }

    .cards-list{

        box-sizing: border-box;
        padding: 20px 0;

        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 15px;
        column-gap: 15px;

        @media screen and (max-width: 1024px) {
            padding: 10px;
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            
        }

        
        .card{
            
            
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 100px;
            column-gap: 0px;
            row-gap: 0px;
            width: 100%;
            margin: 10px auto;
    
            
            .title{
                font-size: 3em !important;
                box-sizing: border-box;
                padding: 10px 0px;
                align-items: center;
                display: flex;
                
            }

            h3{
                font-size: 1.2em;
                font-weight: 400;
                min-height: 50px;
            }

            .content{
                box-sizing: border-box;
                padding: 0px;
                font-size: 1em;

                .button-more{
                    background-color: $color-1;
                    max-width: 120px;
                    box-sizing: border-box;
                    padding: 10px;
                    border-radius: 4px;
                    text-align: center;

                    a{
                        color: #FFF;
                        text-decoration: none;
                    }
                }
            }

            .image{
                grid-column: 1;
                border-radius: 10px;
                overflow: hidden;

                img{
                    width: 100%;
                    margin: 0 auto;
                    display: block;

                }
            }
        }
    }

    .navigation{
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        
        .button{
            background-color: $color-2;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 30px;
            color: #FFF;
            cursor: pointer;
            transition: all linear 600ms 300ms;
            box-sizing: border-box;
            padding: 0 20px;
            font-family: 'Eras Medium ITC', sans-serif;

            &:first-child{
                border-radius: 5px 0 0 5px;
                padding-left: 40px;
            }

            &:last-child{
                border-radius: 0 5px 5px 0;
                padding-right: 40px;
            }

            &.current{
                color: $color-1;
                transition: all linear 600ms;
            }
        }
    }

}