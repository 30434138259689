@import '../../default';

.page-home{
    position: relative;

    > * { 
        position: relative;
    }
    // #olga,
    // #service,
    // #contact,
    // #depoimento{
    //     &::before{
    //         display: block;
    //         width: 100%;
    //         height: 80px;
    //         min-height: 80px;
    //         content: '';

    //         @media screen and (max-width: 600px){
    //             min-height: 30px;
    //             height: 30px;
    //         }
    //     }
    // }


    .banner-home{
        width: 100%;
        height: auto;
        min-height: calc(100vh - 120px);
        background-color: $color-background-1;
        // background-image: url(../../../image/olga_top_1_olga.png), 
        //                   url(../../../image/olga_top_1_bg.jpg);
        // background-size: auto 90%, cover;
        // background-position: bottom center, top left;
        // background-repeat: no-repeat;
        // background-attachment: fixed;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        top: -80px;


      >  .content{
            width: 100%;
            height: 100%;
        }
    }

    .message-olga{
        background-color: $color-1;
        color: #FFF;
        box-sizing: border-box;
        padding: 30px 20px;
        margin: 20px auto;
        
        .message{
            
            position: relative;
            width: 90%;
            max-width: 1000px;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px auto;
            text-align: center;
            font-weight: 200;

            .reference{
                text-align: right;
                margin: 30px auto 0;
                width: 90%;
                box-sizing: border-box;
                padding: 5px;
            }

            .text{
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin: 20px auto 10px;
                box-sizing: border-box;
                padding: 10px 1.5em;
                width: 100%;
                
                .detail-1{
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translate(-50%, -50%);
                    opacity: 0.2;
                    color:#000;
                }
    
                .detail-2{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translate(50%, 50%);
                    opacity: 0.2;
                    color:#000;
                }
            }



            @media screen and (max-width: 600px) {
                font-size: 1em;
            }
        }
    }

    .message-bar{
        background-color: $color-1;
        color: #FFF;
        box-sizing: border-box;
        padding: 30px 20px;
        margin: 20px auto;

        .message{
            width: 90%;
            max-width: 1000px;
            font-size: 1.5em;
            box-sizing: border-box;
            padding: 20px;
            margin: 5px auto;
            text-align: center;
            font-weight: 200;

            @media screen and (max-width: 600px) {
                font-size: 1em;
            }
        }

        .reference{
            margin: 0 auto;
            width: 90%;
            max-width: 1000px;
            font-size: 0.9em;
            box-sizing: border-box;
            padding: 20px 40px;
            text-align: right;
        }
    }

    .services{
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        box-sizing: border-box;
        z-index: 20;
        position: relative;

        @media screen and (max-width: 1024px) {
            box-sizing: border-box;
            padding: 20px;
        }

        .cards-services{
            box-sizing: border-box;
            padding: 10px 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            min-height: 300px;
            margin: 30px auto 20px;

            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;
                row-gap: 10px;
                column-gap: 10px;
            }
            

            .card{
                border: 0px solid #f6d5c6;
                box-sizing: border-box;
                padding: 20px;
                


                &:first-child{
                    border-radius: 10px 0 0 10px;
                }

                &:last-child{
                    border-radius: 0 10px 10px 0;
                }

                @media screen and (max-width: 600px) {
                    width: 98%;
                    margin: 0 auto;

                    border-radius: 10px !important;
                }

                .area-icon{
                    text-align: center;
                    .icon{
                        width: 100px;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-color: $color-1;
                        color: #FFF;
                        margin: 0 auto;
                        position: relative;

                        div{
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            border-radius: 50%;
                            border: 2px solid #FFF;
                            top: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $color-1;
                        }
                    }
                }

                + .card{
                    border-left: 0;
                    @media screen and (max-width: 600px) {
                        border-left: 0px solid #f6d5c6 !important;
                    }
                }

                .area-title{
                    font-weight: 800;
                    font-size: 2em;
                    box-sizing: border-box;
                    padding: 20px 0;
                    font-family: 'Eras Medium ITC', sans-serif;
                    color: $color-1;
                    text-align: center;
                }
            }
        }
    }

    .area-image-olga{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .olga-image{
        width: 90%;
        height: 90%;
        background-image: url(../../../image/sobre1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        //background-attachment: fixed;
        background-position: center center;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;

        @media screen and (max-width: 600px) {
            width: 98%;
            min-height: 300px;
        }

        @media screen and (max-width: 768px) {
            width: 98%;
            min-height: 600px;
        }

    }

    .olga-text{
        @media screen and (max-width: 1024px) {
            box-sizing: border-box;
            padding: 20px;
        }
    }    

    .card-word{
        z-index: 10;
    }

    .area-depoiment{
        z-index: 9;
    }
    
}