@import '../../../default';

.page-admin-user{

    header{
        height: 80px;
        width: 100%;
        background-color: $color-1;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: 10px 20px;

        .button-close{
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            color: $color-1;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    section{
        .content{
            width: 100%;
            max-width: 1000px;
            margin: 20px auto;
            box-sizing: border-box;
            padding: 20px;
        }
    }

    .area-link{
        display: grid;
        grid-template-columns: repeat(auto-fit, 120px);
        margin: 20px auto;
        justify-content: flex-end;
        row-gap: 15px;
        column-gap: 15px;

        > div{
            color: $color-1;
            background-color: $color-5;
            text-align: center;
            box-sizing: border-box;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;

            a{
                color: $color-1;
                text-decoration: none;
            }
        }
    }
}