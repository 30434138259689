@import '../../../default';

.ui-footer{
    background-color: $color-1;
    color: #FFF;
    box-sizing: border-box;
    padding: 20px;
    max-width: 100vw;

    @media screen and (max-width: 600px) {
        padding: 20px 10px;
    }

    &::after{
        display: block;
        width: 100%;
        height: 50px;
        content: '';
    }

    > * {
        max-width: 100vw;
    }

    .footer{
        width: 100%;
        max-width: 1000px;
        margin: 10px auto;
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        align-items: flex-start;

        > div:first-child{
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 0.9em;

            > div{
                margin: 5px auto;
                width: 100%;

                &:last-child{
                    font-size: 0.8em;
                }
            }
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            > div:first-child{
                div{
                    box-sizing: border-box;
                    padding: 10px 0;
                }
            }
        }

        .menu,
        .menu-services{

            a{
                color: #FFF;
                text-decoration: none;

                &:hover{
                    text-decoration: underline;
                }
            }

            margin: 0;
            padding: 0;
            font-size: 0.8em;

            li{
                list-style: none;
                margin: 10px 0;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .footer-links{
            display: grid;
            grid-template-columns: 1fr 110px;

            @media screen and (max-width: 600px) {
                row-gap: 15px;
                grid-template-columns: 1fr;
                margin: 10px 0 15px;
            }

            .footer-socialmedia{
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
                column-gap: 10px;
                row-gap: 10px;

                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-1;
                    background-color: #FFF;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;

                }
            }

        }

        .links-extra{
            box-sizing: border-box;
            padding: 10px 0;
            display: grid;
            grid-template-rows: repeat(auto-fill, minmax(30px, 1fr));
            justify-content: flex-start;
            text-align: left;

            > * {
                cursor: pointer;
                min-width: 300px;
            }
            .whatsapp, a,
            .email, a{
                color: #FFF;
                text-decoration: none;
                margin: 5px auto;
                justify-content: flex-start;
                text-align: left;
                cursor: pointer;
            }
        }
    }
}