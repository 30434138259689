@import '../../default';

.page-post{

    &::before{
        display: none !important;
    }

    display: grid;
    grid-template-rows: 400px 1fr max-content;
    width: 100%;
    height: 100%;
    max-width: 100vw;

    > * {
        max-width: 100vw;
    }

    header{
        background-color: $color-1;
        color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:  center;
        text-align: center;
        box-sizing: border-box;
        padding: 5px 20px;
        position: relative;

        .image{
            background-color: $color-1;
            display: block;
            position: absolute;
            top: 0;
            height: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            z-index: 5;
            background-position: var(--position);


            &::before{
                position: absolute;
                background-color: $color-1;
                opacity: 0.75;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: block;
                content: '';
            }
        }

        &::before{
            content:'';
            height: 90px;
            width: 100%;
            display: block;
        }

        .title,
        .subtitle{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            position: relative;
            z-index: 10;
        }

        .title{
            font-size: 2em;
            font-weight: 800;
        }

        .subtitle{
            font-size: 1.2em;
            font-weight: 200;
        }
    }

    .content-area{
        width: 100%;
        
        .title{
            font-size: 2em;
            font-weight: 800;
            color: $color-1;
        }

        .content{
            width: 100%;
            max-width: 1000px;
            margin: 20px auto;
            box-sizing: border-box;
            padding: 20px 10px;

            @media screen and (max-width: 1024px) {
                padding: 20px;
            }
        }

        .box-area{
            width: 100%;
            min-height: 200px;
            box-sizing: border-box;
            padding: 20px;
            background-color: $color-1;
            color: #FFF;
            margin: 10px auto;
        }

        .box-area{
            width: 100%;
            min-height: 200px;
            box-sizing: border-box;
            padding: 20px;
            background-color: $color-1;
            color: #FFF;
            margin: 10px auto;

            .area-gallery{
                width: 100%;
                max-width: 1000px;
                margin: 20px auto;
                box-sizing: border-box;
            }

            .box-content{
                width: 100%;
                max-width: 1000px;
                margin: 20px auto;
                box-sizing: border-box;
                padding: 20px 10px;

                ul{
                    counter-reset: idx;

                    @media screen and (max-width: 600px){
                        margin: 0;
                        padding: 0;
                    }
                    
                    li{
                        counter-increment: idx;
                        display: flex;
                        flex-direction: row;
                        list-style: none;
                        margin: 5px 0;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0;

                        &::before{
                            content: counter(idx);
                            display: inline-flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0 20px 0 0;
                            font-size: 4em;

                        }
                    }
                }
            }
        }
    }

    .list-manual{
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-auto-columns: 1fr 1fr;  
        
        @media screen and (max-width: 600px){
            margin: 0;
            padding: 0;
            grid-template-rows: unset;
            grid-template-columns: 1fr;
            grid-auto-columns: unset;  
            grid-auto-flow: unset;
        }

        > div{
            display: grid;
            grid-template-columns: 30px 1fr;
            box-sizing: border-box;
            padding: 10px;
            align-items: baseline;

            > div:first-child{
                font-size: 2em;
                font-weight: bold;

            }
        }
    }

    .list-manual-2{
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;
        grid-auto-columns: 1fr 1fr;  
        
        @media screen and (max-width: 600px){
            margin: 0;
            padding: 0;
            grid-template-rows: unset;
            grid-template-columns: 1fr;
            grid-auto-columns: unset;  
            grid-auto-flow: unset;
        }

        > div{
            display: grid;
            grid-template-columns: 30px 1fr;
            box-sizing: border-box;
            padding: 10px;
            align-items: baseline;

            > div:first-child{
                font-size: 2em;
                font-weight: bold;

            }
        }
    }

    .list-check{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 600px){
            grid-template-columns: 1fr;
            margin: 0;
            padding: 0;
        }

        li{
            list-style: none;

            &::before{
                content: '\e81c' !important;
                font-family: 'icon';
                width: 30px;
                height: 30px;
                background-color: #FFF;
                border-radius: 50%;
                margin-right: 15px;
                color: $color-1;
                font-size: 1em !important;
                display: flex !important;
                align-items: center;
                justify-content: center;
                padding: unset !important;
            }
        }
    }

    .title-group{
        font-size: 2em;
        font-weight: bold;
        color: $color-1;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-1;
        margin: 10px auto 20px;

        &::before{
            width: 30px;
            max-width: 30px;
            min-width: 30px;
            height: 30px;
            max-height: 30px;
            min-height: 30px;    
            display: inline-block;
            content: '';
            background-color: $color-1;
            border-radius: 50px;
            margin-right: 15px;
        }
    }

    .list-check{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 600px){
            grid-template-columns: 1fr;
            margin: 0;
            padding: 0;
        }

        li{
            list-style: none;
            display: flex;
            margin: 10px 0;
            width: 100%;

            &::before{
                content: '\e81c' !important;
                font-family: 'icon';
                width: 30px;
                min-width: 30px;
                height: 30px;
                min-height: 30px;
                background-color: $color-1;
                border-radius: 50%;
                margin-right: 15px;
                color: #FFF;
                font-size: 1em !important;
                display: flex !important;
                align-items: center;
                justify-content: center;
                padding: unset !important;
            }
        }
    }

    .card-image{
        position: relative;
        background-color: $color-1;
        border-radius: 10px;
        overflow: hidden;
        max-height: 320px;
        img{
            width: 100%;
            height: auto;
            display: block;
        }

        .text{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding: 30px 20px;
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 1.5em;
            color: #FFF;
        }
    }

    .bt-more{
        color: #FFF;
        background-color: $color-1;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
        border: 0;
        min-width: 120px;
        cursor: pointer;
        margin: 20px auto;
        display: block;
    }
}