@import '../../default';

.page-admin{
    background-color: $color-1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;

    &::before{
        display: none !important;
    }

    .box{
        width: 90%;
        max-width: 300px;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 20px;
        outline: 3px solid rgba(255, 255, 255, 0.6);
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
                    -5px -5px 10px rgba(255, 255, 255, 0.2);

        form{
            width: 100%;
        }

        .logo{

            text-align: center;

            img{
                max-width: 200px;
            }
        }

        .input-area{
            margin: 10px auto;
            display: flex;

            &.aling-center{
                justify-content: center;
            }

            input{
                width: 100%;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 5px;
                border: 0;
            }

            button{
                background-color: $color-1;
                color: #FFF;
                border-radius: 5px;
                border: 0;
                box-sizing: border-box;
                padding: 10px 20px;
            }
        }

        h3{
            text-align: center;
            color: #FFF;
            font-weight: 400;
        }
    }

}