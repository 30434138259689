@import '../../../default';

.ui-gallery{
    .area-card{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 150px));
        row-gap: 15px;
        column-gap: 15px;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 600px) {
            row-gap: 15px;
            column-gap: 15px;
            grid-template-columns: 1fr 1fr;
        }

        .card{
            cursor: pointer;
            overflow: hidden;
            border-radius: 4px;

            img{
                width: 100%;
                display: block;
                transition: ease all 600ms 200ms;
                filter: grayscale(1);
            }

            &:hover{
                img{
                    transition: ease all 600ms;
                    filter: grayscale(0);
                }
            }
        }
    }

    .area-show{
        position: fixed;
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        transition: all ease 600ms 200ms;



        &.active{
            opacity: 1;
            pointer-events: auto;
            transition: all ease 600ms;

            .content{
                transition: ease all 300ms 200ms;
                transform: scale(1);
            }
        }

        .button-close{
            width: 32px;
            height: 32px;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 100;
            cursor: pointer;
        }

        .navigation{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 90;
            transform: translateY(-50%);

            > [class*="button-"]{
                display: flex;
                align-items: center;
                width: 32px;
                height: 64px;
                background-color: rgba(0, 0, 0, 0.3);
                transition: ease all 600ms 300ms;
                cursor: pointer;

                &:hover{
                    transition: ease all 600ms;
                    transform: scale(1.2);
                }
            }

        }

        .content{
            width: 98%;
            max-width: 1000px;
            box-sizing: border-box;
            padding: 5px;
            background-color: #FFF;
            overflow: hidden;

            transition: ease all 300ms;
            transform: scale(0);

            > * {
                display: block;
                width: 100%;
                user-select: none;
            }
        }
    }
}