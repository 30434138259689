@import './default';

#root{
    position: relative;
    top: 80px;

    .page-admin{
        position: fixed;
        top: 0;
    }
}

#resume{
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
}